<template>
      <div class="demo-card-wide mdl-card mdl-shadow--2dp">
        <div class="mdl-card__title">
            <div class="box">
                <div class="title">
                    <span style="fontSize:24px;color:#fff;">「E快讯」客户管理系统</span>
                </div>
                <Form ref="loginInfo" :model="loginInfo" :rules="ruleInline">
                    <FormItem prop="uname" style="margin-bottom: 24px;">
                        <Input v-model="loginInfo.uname" clearable placeholder="账号......">
                            <Icon type="ios-person-outline" slot="prepend"/>
                        </Input>
                    </FormItem>
                    <FormItem prop="password" style="margin-bottom: 24px;">
                        <Input type="password" v-model="loginInfo.password" clearable placeholder="密码......">
                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                        </Input>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" long @click="login('loginInfo')" :loading="logLoading">登录</Button>
                    </FormItem>
                </Form>
            </div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
        loginInfo: {
            uname: '',
            password: ''
        },
        ruleInline: {
            uname: [
                { required: true, message: '请输入你的账号.', trigger: 'blur' },
                { type: 'string', min: 1, trigger: 'blur' }
            ],
            password: [
                { required: true, message: '请输入你的密码.', trigger: 'blur' },
                { type: 'string', min: 6, trigger: 'blur' }
            ]
        },
        logLoading: false
    }
  },
  mounted(){
      if (this.$store.state.hasLogin) {
          this.$router.replace('/');
      } else {
          const token = localStorage.getItem('token');
          if (token) {
              this.apiV1({
                method: 'get',
                url: '/uinfo',
                headers: {'Authorization': token}
              })
              .then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.$Message.success('自动登录');
                    this.$store.commit('login', { uinfo: data, token })
                    this.$router.replace('/');
                } else {
                    localStorage.removeItem('token');
                }
              })
          }
      }
  },
  methods: {
      // 登录
      login(name) {
          this.$refs[name].validate((valid) => {
              if (valid) {
                this.logLoading = true;
                this.apiV1({
                    method: 'post',
                    url: '/login',
                    data: this.loginInfo
                }).then(response => {
                    const { success, data } = response.data;
                    if (success) {
                        this.$store.commit('login', data);
                        localStorage.setItem('token', data.token);
                        this.$Message.success('登录成功');
                        this.$router.replace('/');
                    }
                }).finally(() => this.logLoading = false);
              } else {
                  this.$Message.error('输入有误');
              }
          })
      }
  }
}
</script>
<style lang="scss" scoped>
.demo-card-wide {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2d3a4b;
}
.box {
    width: 90vw;
    max-width: 335px;
    border-radius: 5px;
    padding: 15px 30px 15px 30px;
    background: rgba($color: #000, $alpha: 0.2);
    .title {
        position: relative;
        height: 60px;display: flex;justify-content: center;align-items: center;
    }
}
</style>
